
import {computed, defineComponent, onMounted, ref} from "vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {GetSpaceId, LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import {useTaskStore} from "@/store/task.store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskName from "@/views/task/TaskName.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import UserLink from "@/views/user/UserLink.vue";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import TeamLink from "@/views/team/TeamLink.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";

export default defineComponent({
  name: "TaskToApproval",
  components: {TaskDueDate, TeamLink, SearchHorizontal, UserLink, UserListSelect, TaskName, DateTimeFormat, KtDatatable},

  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Tasks To Approve",
        [
          {link: false, router: '', text: 'Tasks To Approve'}
        ]
      )
    })

    const store = useTaskStore();
    const filterObjects = ref({
      approvalId: '',
      status: 'COMPLETED',
      approvalStatus: 'PENDING',
      overdue: false,
      assignedId: ''
    })
    const page = computed(() => store.page);
    const headers = [
      {
        name: 'Created',
        key: 'created',
        sortable: false
      },
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'ApproveBy',
        key: 'approvalBy',
        sortable: false
      },
      {
        name: 'Due Date',
        key: 'dueDate',
        sortable: false
      },
      {
        name: 'Completed',
        key: 'completedDt',
        sortable: false
      },
    ]
    return {
      headers,
      page,
      filterObjects,
      ...GetSpaceId(),
      ...PinaFilterObjects(store, filterObjects.value, ['createdBy', 'owner', 'team', 'approvalBy']),
      ...LoadPanel()
    }
  },
  methods: {
    searchEvent() {

      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.filterObjects.overdue = false
      this.updateFilterObject(this.filterObjects);
    },
  }
})
